import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-salon.jpg'
import forBizSvg from '../images/icon-toolkit-cloud.svg'
import toolkitTeaserImg from '../images/toolkit-teaser@2x.png'
import iconInstagramCircle from '../images/icon-instagram-circle.svg'
import iconTwitterCircle from '../images/icon-twitter-circle.svg'
import iconFacebookCircle from '../images/icon-facebook-circle.svg'

const description = "We’ve created a free downloadable toolkit that you can download to show customers you're a 'Covid-Conscious' business!";
const Toolkit = () => (
  <Layout>
    <PageHelmet
      title="Free Toolkit"
      description={description}
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Free'}
      accentedTitle={'Toolkit'}
      subtitle={
        'Thank you for registering as a \'Covid-Conscious\' business!'
      }
    />

    <section key="mow-forbiz-toolkit">
      <div className="columns is-marginless v-align-center">
        <div className="column home-half-text-left">
              <p>
                {description} It includes:
              </p>
              <div className="content">
                <h3>Printable Toolkit</h3>
                <h5>Full Download</h5>
                Includes: 11x8.5in horizontal flyer, 8.5x11 vertical flyer, 4x4in print and cut window graphics, and daily health log example.
                  <ul>
                    <li>
                      <a
                        href="../downloads/Mask-of-Wellness-full-digital-toolkit.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Full toolkit</a> (Masks not required for patrons version)
                    </li>
                    <li>
                      <a
                        href="../downloads/MOW-toolkit-masks-required-requested.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Full toolkit</a> (Masks required/requested for patrons version)
                    </li>
                  </ul>
                <h5>Printable Flyers & Graphics</h5>
                <p>
                  Hang near your entryway.
                </p>
                <ul>
                  <li>
                    <a
                      href="../downloads/horizontal-flyer.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      11x8.5in Horizontal flyer with icons
                    </a>
                  </li>
                  <li>
                    <a
                      href="../downloads/vertical-flyer.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      8.5x11in Vertical text-based flyer
                    </a>
                  </li>
                  <li>
                    <a
                      href="../downloads/mow-print-cut-window-graphics.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4x4in Print and cut window graphics
                    </a>
                  </li>
                </ul>
                <h5>Daily Health Log</h5>
                <p>
                  An example you can adapt for your business.
                </p>
                <ul>
                  <li>
                    <a
                      href="../downloads/MOW-daily-health-log-example.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Daily health log example
                    </a>
                  </li>
                </ul>
                <h3>Digital Graphics</h3>
                <h5>For Your Website</h5>
                <p>
                  Display on your website, social media, and in digital
                  marketing.
                </p>
                <ul>
                  <li>
                    <a
                      href="../downloads/logo-no-text.png"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Square Mask logo without text
                    </a>
                  </li>
                  <li>
                    <a
                      href="../downloads/logo-with-text.png"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Square Mask logo with text
                    </a>
                  </li>
                  <li>
                    <a
                      href="../downloads/logo-with-text-and-border.png"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Square Mask logo with text and border
                    </a>
                  </li>
                  <li>
                    <a
                      href="../downloads/logo-horizontal-with-text.png"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Horizontal Mask logo with text and border
                    </a>
                  </li>
                </ul>

                <h3 className="title">Products</h3>
                <p>
                  If you'd like to purchase stickers, window clings, and t-shirts
                  see our <Link to="/products/">products page</Link>.
                </p>
              </div>
        </div>
        <div className="column square-bg-biz-toolkit">
        </div>
    </div>
    </section>

    <section>
      <div className="divider-title">
        <h3>Social Media</h3>
      </div>
      <div className="columns has-text-centered">
        <div className="column toolkit-social-text">
          <h5>Posts to Share</h5>
          <p>
            "We're proud to join #themaskofwellness community! Check out our
            website to learn how we're changing to stay open responsibly
            during #COVID19."
          </p>
          <p>
            "We're taking a positive approach promoting responsible behavior
            necessary to keep society open during #COVID19 while benefiting
            social, physical, and economic wellness."
          </p>
        <h5>Follow Us</h5>
        <a
          href="https://www.instagram.com/covidconsciousbusinesses/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-social-icon"
        >
          <img alt="Instagram logo" src={iconInstagramCircle} />
        </a>
        <a
          href="https://twitter.com/covidconscious"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-social-icon"
        >
          <img alt="Twitter logo" src={iconTwitterCircle} />
        </a>
        <a
          href="https://www.facebook.com/covidconsciousbusinesses"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-social-icon"
        >
          <img alt="Facebook logo" src={iconFacebookCircle} />
        </a>
        <p>
          <span className="accent">#</span>themaskofwellness
          <br />
          <span className="accent">#</span>covidconsciousbusiness
          <br />
          <span className="accent">#</span>covid-consciousbusiness
          <br />
          <span className="accent">#</span>maskofwellnessbusiness
          <br />
        </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Toolkit
